import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          // here we will place our translations...
          Header: {
            Home: "Home",
            About: "About",
            Services: "Services",
            Partners: "Partners",
            Contact: "Contact",
          },

          Home: {
            Header: "Fast, Reliable, and Exquisitely Designed Software Solutions.",
            Who: "Who we are",
            Leaders: "Leaders in Development:",
            Ded: "Dedicated to Your Success:",
            Quality: "Quality-Driven Software Developers:",
            Services: "Our Services",
            Web: "Web Development",
            App: "App Development",
            SEO: "Social Media Marketing and SEO",
            Contact: "Contact/Follow Us",
            LText: "We are recognized leaders in software, web, and app development. Our extensive experience allows us to take on projects of all complexities, ensuring your digital vision becomes a reality.",
            DText: "BetStup is more than a service provider, we're your dedicated partners in software success. Your project's success is our ultimate goal, and we're committed to making it happen.",
            QText: "Quality is our hallmark. Our software development process incorporates rigorous quality assurance to ensure that the end product is not just functional but also robust, secure, and reliable.",
          },

          About: {
            Mission: "Our Mission:",
            Innovation: "Innovating Software, Elevating Business.",
            Abus: `
            Founded in 2023, BetStup is dedicated 
            to delivering the latest technology 
            and innovative design to transform 
            businesses. We're committed 
            to ensuring our clients have the 
            best possible experience, making their 
            operations more efficient and 
            customer-focused.`,
            Ouar1: "- We are a forward-thinking company, staying at the forefront of technological advancements.",
            Ouar2: "- We understand the unique needs of every business and tailor our solutions accordingly.",
            Ouar3: "- Your success is our success, and we're committed to delivering exceptional quality and reliability.",
            Geto: `
            Contact BetStup today and let us be 
            your partner in harnessing the 
            power of technology to elevate your business.
            `,
          },

          Services: {
            Our: "Our Services",
            Web: `Web development is our expertise in transforming your online presence. 
            Our team of skilled developers crafts websites that are not 
            just visually appealing but also function seamlessly. 
            From the user interface to the backend infrastructure, 
            we ensure that your website is a powerful tool for your business. 
            Whether you need a simple, informative website or a complex 
            e-commerce platform, we've got you covered.`,
            App: `
            App development is where innovation meets the mobile world. 
            We specialize in creating mobile applications that enhance 
            your business operations or engage your customers. 
            Our team is proficient in developing for both iOS and 
            Android platforms, delivering user-friendly, feature-rich apps. 
            Whether it's a business utility app, 
            or a platform for communication, 
            we have the skills to bring your app ideas to life.
            `,
            SEO: `
            In the digital age, having a great product or service isn't enough; you need a strong online presence. 
            Social Media Marketing (SMM) and Search Engine Optimization (SEO) are vital for getting your business noticed. 
            Our SMM experts craft engaging social media strategies to connect with your audience, increase brand awareness, 
            and drive conversions. Simultaneously, our SEO team ensures 
            your website ranks high in search engine results, increasing 
            your visibility to potential customers.
            `,
            Sum: `
            These services are designed to help your business 
            thrive in the digital landscape, from creating a solid web 
            presence to ensuring your target audience can easily 
            find you and leveraging the power of social media for growth.
            `,
          },

          Partners: {
            Headline: "Partners with BetStup",
            Part1: "When you choose BetStup as your partner, you're choosing innovation, expertise, and a commitment to your success. We don't just develop technology; we forge meaningful partnerships to take businesses to the next level.",
            Part2: "Our dedicated team of experts is passionate about delivering cutting-edge solutions that transform your operations and engage your customers. With a focus on quality, transparency, and on-time delivery, we make your goals our own.",
            Part3: "BetStup is more than a service provider; we're your journey to success in the digital world. Let's partner for progress, explore the latest technologies, and pioneer your business's future together.",
            Working: "BetStup is working with industry leaders:",
          },

          Contact: {
            Headline: "Contact Us",
            Dino: "Disclaimer Notice:",
            DinoText: "While we exercise diligence in reviewing our content, we do not assume responsibility for the content of external links. The content of linked pages is the sole responsibility of their respective operators.",
            ConUs: "Contact Us:",
            ConUsText: "Feel free to reach out to us at any time. Our dedicated team will promptly address your inquiries.",
          },
        }
      },
      de: {
        translation: {
          // here we will place our translations...
          Header: {
            Home: "Start",
            About: "Über uns",
            Services: "Services",
            Partners: "Partner",
            Contact: "Kontakt",
          },

          Home: {
            Header: "Schnelle, zuverlässige und exquisit entworfene Softwarelösungen.",
            Who: "Wer wir sind",
            Leaders: "Anführer in der Entwicklung:",
            Ded: "Ihrem Erfolg gewidmet:",
            Quality: "Qualitätsorientierte Softwareentwickler:",
            Services: "Unsere Dienstleistungen",
            Web: "Web Entwicklung",
            App: "App Entwicklung",
            SEO: "Social Media Marketing und SEO",
            Contact: "Kontaktieren/Folgen Sie uns",
            LText: "Wir sind anerkannte Anführer in der Software-, Web- und App-Entwicklung. Unsere umfangreiche Erfahrung ermöglicht es uns, Projekte aller Komplexitätsgrade zu übernehmen, um sicherzustellen, dass Ihre digitale Vision Wirklichkeit wird.",
            DText: "BetStup ist mehr als ein Dienstleister, wir sind Ihre engagierten Partner für den Softwareerfolg. Der Erfolg Ihres Projekts ist unser ultimatives Ziel, und wir sind entschlossen, dies zu verwirklichen.",
            QText: "Qualität ist unser Markenzeichen. Unser Softwareentwicklungsprozess beinhaltet strenge Qualitätssicherung, um sicherzustellen, dass das Endprodukt nicht nur funktional, sondern auch robust, sicher und zuverlässig ist.",
          },

          About: {
            Mission: "Unsere Mission:",
            Innovation: "Software-Innovationen, Geschäftsförderung.",
            Abus: `Gegründet im Jahr 2023, ist BetStup darauf spezialisiert, 
            die neueste Technologie und innovatives Design bereitzustellen, 
            um Unternehmen zu transformieren. Wir setzen uns dafür ein, 
            dass unsere Kunden die bestmögliche Erfahrung machen, ihre Abläufe 
            effizienter und kundenorientierter gestalten können.`,
            Ouar1: "- Wir sind ein zukunftsorientiertes Unternehmen und bleiben an der Spitze technologischer Fortschritte.",
            Ouar2: "- Wir verstehen die einzigartigen Bedürfnisse jedes Unternehmens und passen unsere Lösungen entsprechend an.",
            Ouar3: "- Ihr Erfolg ist unser Erfolg und wir setzen uns für außergewöhnliche Qualität und Zuverlässigkeit ein.",
            Geto: `Kontaktieren Sie BetStup heute und lassen Sie uns Ihr Partner sein, um die Kraft der Technologie zu nutzen und Ihr Geschäft zu fördern.`
          },
          Services: {
            Our: "Unsere Services",
            Web: `
            Webentwicklung ist unsere Expertise, um Ihre Online-Präsenz zu transformieren. 
            Unser Team aus geschickten Entwicklern erstellt Websites, die nicht nur visuell ansprechend, 
            sondern auch funktional reibungslos sind. Von der Benutzeroberfläche bis zur Backend-Infrastruktur 
            sorgen wir dafür, dass Ihre Website ein leistungsstarkes Tool für Ihr Geschäft ist. Ob Sie eine einfache, 
            informative Website oder eine komplexe E-Commerce-Plattform benötigen, wir haben Sie abgedeckt.
            `,
            App: `
            App-Entwicklung ist dort, wo Innovation auf die mobile Welt trifft. 
            Wir sind spezialisiert auf die Erstellung mobiler Anwendungen, die Ihre Geschäftsabläufe verbessern oder Ihre Kunden binden. 
            Unser Team ist kompetent in der Entwicklung sowohl für iOS- als auch für Android-Plattformen und liefert benutzerfreundliche, 
            funktionsreiche Apps. Ob es sich um eine Geschäftsanwendung 
            oder eine Kommunikationsplattform handelt, wir haben die Fähigkeiten, Ihre App-Ideen zum Leben zu erwecken.
            `,
            SEO: `
            Im digitalen Zeitalter reicht ein großartiges Produkt oder Dienstleistung nicht aus; 
            Sie benötigen eine starke Online-Präsenz. Social Media Marketing (SMM) und Suchmaschinenoptimierung (SEO) 
            sind entscheidend, um Ihr Geschäft bemerkbar zu machen. 
            Unsere SMM-Experten entwickeln ansprechende Social-Media-Strategien, um sich mit Ihrem Publikum zu verbinden, 
            die Markenbekanntheit zu erhöhen und Conversions zu steigern. Gleichzeitig stellt unser SEO-Team sicher, 
            dass Ihre Website in den Suchmaschinenergebnissen hoch eingestuft wird und Ihre Sichtbarkeit für potenzielle Kunden erhöht.
            `,
            Sum:`
            Diese Dienstleistungen sind darauf ausgelegt, Ihr Unternehmen in der digitalen Landschaft gedeihen zu lassen, 
            von der Schaffung einer soliden Webpräsenz bis hin zur Gewährleistung, 
            dass Ihre Zielgruppe Sie leicht finden kann und die Kraft der sozialen Medien für das Wachstum nutzt.
            `,
          },

          Partners: {
            Headline: "Partner mit BetStup",
            Part1: "Wenn Sie BetStup als Ihren Partner wählen, wählen Sie Innovation, Expertise und Engagement für Ihren Erfolg. Wir entwickeln nicht nur Technologie; wir schmieden bedeutungsvolle Partnerschaften, um Unternehmen auf die nächste Stufe zu bringen.",
            Part2: "Unser engagiertes Team von Experten ist leidenschaftlich daran interessiert, bahnbrechende Lösungen zu liefern, die Ihre Abläufe transformieren und Ihre Kunden begeistern. Mit einem Fokus auf Qualität, Transparenz und pünktliche Lieferung machen wir Ihre Ziele zu unseren eigenen.",
            Part3: "BetStup ist mehr als ein Dienstleister; wir sind Ihr Weg zum Erfolg in der digitalen Welt. Lassen Sie uns gemeinsam für den Fortschritt arbeiten, die neuesten Technologien erkunden und gemeinsam die Zukunft Ihres Unternehmens gestalten.",
            Working: "BetStup arbeitet mit Branchenführern zusammen:"
          },

          Contact: {
            Headline: "Kontakt",
            Dino: "Haftungsausschluss:",
            DinoText: "Obwohl wir bei der Überprüfung unserer Inhalte Sorgfalt walten lassen, übernehmen wir keine Verantwortung für den Inhalt externer Links. Der Inhalt verlinkter Seiten liegt in der alleinigen Verantwortung ihrer jeweiligen Betreiber.",
            ConUs: "Kontaktieren Sie uns:",
            ConUsText: "Zögern Sie nicht, uns jederzeit zu kontaktieren. Unser engagiertes Team wird Ihre Anfragen umgehend bearbeiten."
          },
        }
      },
      es: {
        translation: {
          // here we will place our translations...
          Header: {
            Home: "Inicio",
            About: "Acerca de",
            Services: "Servicios",
            Partners: "Socios",
            Contact: "Contacto"
          },

          Home: {
            Header: "Soluciones de Software Rápidas, Confiables y Exquisitamente Diseñadas.",
            Who: "Quiénes somos",
            Leaders: "Líderes en Desarrollo:",
            Ded: "Dedicados a tu éxito:",
            Quality: "Desarrolladores de Software Orientados a la Calidad:",
            Services: "Nuestros Servicios",
            Web: "Desarrollo Web",
            App: "Desarrollo de Aplicaciones",
            SEO: "Marketing en Redes Sociales y SEO",
            Contact: "Contacto/Síguenos",
            LText: "Somos líderes reconocidos en software, desarrollo web y de aplicaciones. Nuestra amplia experiencia nos permite asumir proyectos de todas las complejidades, asegurando que su visión digital se convierta en realidad.",
            DText: "BetStup es más que un proveedor de servicios, somos sus socios dedicados al éxito del software. El éxito de su proyecto es nuestro objetivo final, y estamos comprometidos a hacerlo realidad.",
            QText: "La calidad es nuestra seña de identidad. Nuestro proceso de desarrollo de software incorpora una rigurosa garantía de calidad para asegurar que el producto final no solo sea funcional, sino también robusto, seguro y fiable.",
          },

          About: {
            Mission: "Nuestra Misión:",
            Innovation: "Innovando Software, Elevando Negocios.",
            Abus: `Fundada en 2023, BetStup se dedica a ofrecer la última tecnología y diseño innovador para transformar negocios. 
            Estamos comprometidos a garantizar que nuestros clientes tengan la mejor experiencia posible, 
            haciendo que sus operaciones sean más eficientes y enfocadas al cliente.`,
            Ouar1: "- Somos una empresa con visión de futuro, manteniéndonos a la vanguardia de los avances tecnológicos.",
            Ouar2: "- Entendemos las necesidades únicas de cada negocio y adaptamos nuestras soluciones en consecuencia.",
            Ouar3: "- Tu éxito es nuestro éxito, y estamos comprometidos a ofrecer calidad y fiabilidad excepcionales.",
            Geto: `Contacta a BetStup hoy y permítenos ser tu socio para aprovechar 
            el poder de la tecnología para elevar tu negocio.`,
          },

          Services: {
            Our: "Nuestros Servicios",
            Web: `
            El desarrollo web es nuestra especialidad para transformar su presencia en línea. Nuestro equipo de desarrolladores hábiles crea sitios web que no solo son visualmente atractivos sino que también funcionan sin problemas. Desde la interfaz de usuario hasta la infraestructura de backend, nos aseguramos de que su sitio web sea una herramienta poderosa para su negocio. Ya sea que necesite un sitio web simple e informativo o una plataforma de comercio electrónico compleja, estamos aquí para ayudar.
            `,
            App: `
            El desarrollo de aplicaciones es donde la innovación se encuentra con el mundo móvil. Nos especializamos en crear aplicaciones móviles que mejoren las operaciones de su negocio o involucren a sus clientes. Nuestro equipo es competente en el desarrollo tanto para las plataformas iOS como Android, entregando aplicaciones fáciles de usar y ricas en características. Ya sea una aplicación de utilidad empresarial o una plataforma de comunicación, tenemos las habilidades para dar vida a sus ideas de aplicaciones.
            `,
            SEO: `
            En la era digital, tener un gran producto o servicio no es suficiente; necesita una fuerte presencia en línea. El Marketing en Redes Sociales (SMM) y la Optimización de Motores de Búsqueda (SEO) son vitales para que su negocio sea notado. Nuestros expertos en SMM elaboran estrategias de redes sociales atractivas para conectar con su audiencia, aumentar la conciencia de marca y impulsar conversiones. Al mismo tiempo, nuestro equipo de SEO asegura que su sitio web ocupe un lugar destacado en los resultados de los motores de búsqueda, aumentando su visibilidad para los clientes potenciales.
            `,
            Sum:`
            Estos servicios están diseñados para ayudar a su negocio a prosperar en el panorama digital, desde crear una sólida presencia en la web hasta asegurar que su público objetivo pueda encontrarlo fácilmente y aprovechar el poder de las redes sociales para el crecimiento.
            `,
          },

          Partners: {
            Headline: "Socios con BetStup",
            Part1: "Al elegir BetStup como su socio, está eligiendo innovación, experiencia y un compromiso con su éxito. No solo desarrollamos tecnología; forjamos asociaciones significativas para llevar a las empresas al siguiente nivel.",
            Part2: "Nuestro equipo dedicado de expertos se apasiona por entregar soluciones de vanguardia que transformen sus operaciones y cautiven a sus clientes. Con un enfoque en la calidad, transparencia y entrega a tiempo, hacemos que sus metas sean nuestras.",
            Part3: "BetStup es más que un proveedor de servicios; somos su trayectoria hacia el éxito en el mundo digital. Vamos a asociarnos para el progreso, explorar las últimas tecnologías y liderar juntos el futuro de su empresa.",
            Working: "BetStup está trabajando con líderes de la industria:"
          },

          Contact: {
            Headline: "Contáctanos",
            Dino: "Aviso de exención de responsabilidad:",
            DinoText: "Aunque ejercemos diligencia en la revisión de nuestro contenido, no asumimos responsabilidad por el contenido de los enlaces externos. El contenido de las páginas enlazadas es responsabilidad exclusiva de sus respectivos operadores.",
            ConUs: "Contáctanos:",
            ConUsText: "No dudes en contactarnos en cualquier momento. Nuestro equipo dedicado atenderá tus consultas de manera oportuna."
          },
        }
      },
      fr: {
        translation: {
          // here we will place our translations...
          Header: {
            Home: "Accueil",
            About: "À propos",
            Services: "Services",
            Partners: "Partenaires",
            Contact: "Contact"
          },

          Home: {
            Header: "Solutions Logicielles Rapides, Fiables et Exquisément Conçues.",
            Who: "Qui nous sommes",
            Leaders: "Leaders en Développement:",
            Ded: "Dédié à Votre Succès:",
            Quality: "Développeurs de Logiciels Axés sur la Qualité:",
            Services: "Nos Services",
            Web: "Développement Web",
            App: "Développement d'Applications",
            SEO: "Marketing sur les Réseaux Sociaux et SEO",
            Contact: "Contact/Suivez-nous",
            LText: "Nous sommes des leaders reconnus dans le développement de logiciels, de sites web et d'applications. Notre vaste expérience nous permet de prendre en charge des projets de toutes complexités, assurant ainsi que votre vision numérique devienne une réalité.",
            DText: "BetStup est plus qu'un fournisseur de services, nous sommes vos partenaires dédiés au succès du logiciel. Le succès de votre projet est notre objectif ultime, et nous sommes engagés à le réaliser.",
            QText: "La qualité est notre marque de fabrique. Notre processus de développement logiciel intègre une assurance qualité rigoureuse pour garantir que le produit final ne soit pas seulement fonctionnel, mais aussi robuste, sûr et fiable.",
          },

          About: {
            Mission: "Notre Mission:",
            Innovation: "Innover le Logiciel, Élever l'Entreprise.",
            Abus: `Fondée en 2023, BetStup est dédiée à fournir 
            la dernière technologie et design innovant pour transformer les entreprises. Nous sommes engagés à assurer que 
            nos clients aient la meilleure expérience possible, rendant leurs opérations plus efficaces et axées sur le client.`,
            Ouar1: "- Nous sommes une entreprise tournée vers l'avenir, restant à la pointe des avancées technologiques.",
            Ouar2: "- Nous comprenons les besoins uniques de chaque entreprise et adaptons nos solutions en conséquence.",
            Ouar3: "- Votre succès est notre succès, et nous sommes engagés à fournir une qualité et une fiabilité exceptionnelles.",
            Geto: `Contactez BetStup aujourd'hui et laissez-nous être 
            votre partenaire pour exploiter la puissance de la technologie pour élever votre entreprise.`
          },

          Services: {
            Our: "Nos Services",
            Web: `
            Le développement web est notre expertise pour transformer votre présence en ligne. Notre équipe de développeurs compétents crée des sites web qui sont non seulement visuellement attrayants, mais fonctionnent aussi sans problème. De l'interface utilisateur à l'infrastructure backend, nous veillons à ce que votre site web soit un outil puissant pour votre entreprise. Que vous ayez besoin d'un site web simple et informatif ou d'une plateforme de commerce électronique complexe, nous sommes là pour vous.
            `,
            App: `
            Le développement d'applications est là où l'innovation rencontre le monde mobile. Nous sommes spécialisés dans la création d'applications mobiles qui améliorent vos opérations commerciales ou engagent vos clients. Notre équipe est compétente dans le développement pour les plateformes iOS et Android, fournissant des applications conviviales et riches en fonctionnalités. Qu'il s'agisse d'une application utilitaire pour entreprise ou d'une plateforme de communication, nous avons les compétences pour donner vie à vos idées d'applications.
            `,
            SEO: `
            À l'ère du numérique, avoir un excellent produit ou service ne suffit pas ; vous avez besoin d'une forte présence en ligne. Le Marketing sur les Réseaux Sociaux (SMM) et l'Optimisation pour les Moteurs de Recherche (SEO) sont essentiels pour faire remarquer votre entreprise. Nos experts en SMM élaborent des stratégies de réseaux sociaux captivantes pour se connecter avec votre audience, augmenter la notoriété de la marque et encourager les conversions. Simultanément, notre équipe SEO s'assure que votre site web se classe bien dans les résultats des moteurs de recherche, augmentant ainsi votre visibilité auprès des clients potentiels.
            `,
            Sum:`
            Ces services sont conçus pour aider votre entreprise à prospérer dans le paysage numérique, de la création d'une présence web solide à l'assurance que votre public cible peut vous trouver facilement, en exploitant la puissance des réseaux sociaux pour la croissance.
            `,
          },

          Partners: {
            Headline: "Partenaires avec BetStup",
            Part1: "En choisissant BetStup comme partenaire, vous choisissez l'innovation, l'expertise et l'engagement envers votre succès. Nous ne développons pas seulement la technologie; nous forgeons des partenariats significatifs pour amener les entreprises au niveau supérieur.",
            Part2: "Notre équipe dédiée d'experts est passionnée par la fourniture de solutions de pointe qui transforment vos opérations et engagent vos clients. Avec un accent sur la qualité, la transparence et la livraison à temps, nous faisons de vos objectifs les nôtres.",
            Part3: "BetStup est plus qu'un fournisseur de services; nous sommes votre parcours vers le succès dans le monde numérique. Partenaires pour le progrès, explorons les dernières technologies et pionniers de l'avenir de votre entreprise ensemble.",
            Working: "BetStup travaille avec des leaders de l'industrie:"
          },

          Contact: {
            Headline: "Contactez-nous",
            Dino: "Avis de non-responsabilité :",
            DinoText: "Bien que nous exercions la diligence dans la révision de notre contenu, nous n'assumons aucune responsabilité quant au contenu des liens externes. Le contenu des pages liées est de la seule responsabilité de leurs opérateurs respectifs.",
            ConUs: "Contactez-nous :",
            ConUsText: "N'hésitez pas à nous contacter à tout moment. Notre équipe dédiée répondra rapidement à vos demandes."
          },
        }
      },
      tr: {
        translation: {
          // here we will place our translations...
          Header: {
            Home: "Ana Sayfa",
            About: "Hakkımızda",
            Services: "Hizmetler",
            Partners: "Ortaklar",
            Contact: "İletişim"
          },

          Home: {
            Header: "Hızlı, Güvenilir ve Zarif Tasarlanmış Yazılım Çözümleri.",
            Who: "Biz Kimiz",
            Leaders: "Geliştirme Liderleri:",
            Ded: "Başarınıza Adanmış:",
            Quality: "Kalite Odaklı Yazılım Geliştiricileri:",
            Services: "Hizmetlerimiz",
            Web: "Web Geliştirme",
            App: "Uygulama/App Geliştirme",
            SEO: "Sosyal Medya Pazarlama ve SEO",
            Contact: "İletişim/Bizi Takip Edin",
            LText: "Yazılım, web ve uygulama geliştirmede tanınmış liderleriz. Geniş deneyimimiz, tüm karmaşıklık seviyelerindeki projeleri üstlenmemize olanak tanır, dijital vizyonunuzun gerçeğe dönüşmesini sağlar.",
            DText: "BetStup sadece bir hizmet sağlayıcıdan daha fazlasıdır, yazılım başarısında adanmış ortaklarınızdır. Projenizin başarısı bizim nihai amacımızdır ve bunu gerçekleştirmeye kararlıyız.",
            QText: "Kalite bizim ayırt edici özelliğimizdir. Yazılım geliştirme sürecimiz, nihai ürünün sadece işlevsel değil, aynı zamanda sağlam, güvenli ve güvenilir olmasını sağlamak için sıkı kalite güvencesini içerir.",
          },

          About: {
            Mission: "Misyonumuz:",
            Innovation: "Yazılımı Yenilikçi Kılarak, İşletmeyi Yükseltiyoruz.",
            Abus: "2023 yılında kurulan BetStup, işletmeleri dönüştürmek için en son teknoloji ve yenilikçi tasarımı sunmaya adanmıştır. Müşterilerimizin mümkün olan en iyi deneyimi yaşamalarını sağlamaya, işlemlerini daha verimli ve müşteri odaklı hale getirmeye kararlıyız.",
            Ouar1: "- Biz ileri görüşlü bir şirketiz, teknolojik gelişmelerin ön saflarında kalmaya devam ediyoruz.",
            Ouar2: "- Her işletmenin benzersiz ihtiyaçlarını anlıyor ve çözümlerimizi buna göre uyarlıyoruz.",
            Ouar3: "- Başarınız, başarımızdır ve olağanüstü kalite ve güvenilirlik sunmaya kararlıyız.",
            Geto: "Bugün BetStup ile iletişime geçin ve işletmenizi yükseltmek için teknolojinin gücünden yararlanacak ortağınız olmamıza izin verin.",
          },

          Services: {
            Our: "Hizmetlerimiz",
            Web: `
            Web geliştirme, çevrimiçi varlığınızı dönüştürmede uzmanlığımızdır. Yetenekli geliştiricilerimizden oluşan ekibimiz, sadece görsel olarak çekici değil, aynı zamanda sorunsuz işleyen web siteleri oluşturur. Kullanıcı arayüzünden backend altyapısına kadar, web sitenizin işletmeniz için güçlü bir araç olmasını sağlarız. Basit, bilgilendirici bir web sitesine veya karmaşık bir e-ticaret platformuna ihtiyacınız olup olmadığını, sizin için buradayız.
            `,
            App: `
            Uygulama geliştirme, inovasyonun mobil dünya ile buluştuğu yerdir. İşletme operasyonlarınızı geliştiren veya müşterilerinizle bağlantı kurmanıza yardımcı olan mobil uygulamalar yaratmada uzmanız. Ekibimiz, hem iOS hem de Android platformları için geliştirme konusunda yeteneklidir, kullanıcı dostu, özellikle zengin uygulamalar sunar. İşlevsel bir uygulama mı, yoksa iletişim platformu mu istiyorsunuz, uygulama fikirlerinizi hayata geçirmek için gerekli becerilere sahibiz.
            `,
            SEO: `
            Dijital çağda harika bir ürün veya hizmete sahip olmak yeterli değil; güçlü bir çevrimiçi varlığa ihtiyacınız var. Sosyal Medya Pazarlaması (SMM) ve Arama Motoru Optimizasyonu (SEO), işletmenizin fark edilmesi için hayati öneme sahiptir. SMM uzmanlarımız, kitlelerinizle bağlantı kurmak, marka bilinirliğini artırmak ve dönüşümleri teşvik etmek için çekici sosyal medya stratejileri oluşturur. Aynı zamanda, SEO ekibimiz, web sitenizin arama motoru sonuçlarında yüksek sıralanmasını sağlayarak, potansiyel müşteriler için görünürlüğünüzü artırır.
            `,
            Sum:`
            Bu hizmetler, işletmenizin dijital manzarada gelişmesine yardımcı olmak için tasarlanmıştır, sağlam bir web varlığı oluşturmaktan, hedef kitlenizin sizi kolayca bulabilmesini sağlamaya ve sosyal medyanın gücünden büyüme için yararlanmaya kadar.
            `,
          },

          Partners: {
            Headline: "BetStup ile Ortaklar",
            Part1: "BetStup'u ortağınız olarak seçtiğinizde, yeniliği, uzmanlığı ve başarınıza olan bağlılığı seçersiniz. Biz sadece teknoloji geliştirmiyoruz; işletmeleri bir sonraki seviyeye taşımak için anlamlı ortaklıklar kuruyoruz.",
            Part2: "Dedike ekibimiz, operasyonlarınızı dönüştüren ve müşterilerinizle bağlantı kuran keskin çözümleri sunmaya tutkuludur. Kalite, şeffaflık ve zamanında teslimat üzerine odaklanarak, hedeflerinizi kendimizin yaparız.",
            Part3: "BetStup, sadece bir hizmet sağlayıcı değil; dijital dünyada başarınıza yönelik yolculuğunuzuz. İlerleme için ortak olalım, en son teknolojileri keşfedelim ve işletmenizin geleceğini birlikte öncülük edelim.",
            Working: "BetStup, sektör liderleriyle çalışıyor:"
          },

          Contact: {
            Headline: "Bize Ulaşın",
            Dino: "Sorumluluk Reddi Bildirimi:",
            DinoText: "İçeriğimizi gözden geçirirken özen gösteriyor olsak da, harici bağlantıların içeriği için sorumluluk kabul etmiyoruz. Bağlantılı sayfaların içeriği yalnızca kendi işletmecilerinin sorumluluğundadır.",
            ConUs: "Bize Ulaşın:",
            ConUsText: "Bize her zaman ulaşmaktan çekinmeyin. Adanmış ekibimiz sorularınıza hızlı bir şekilde yanıt verecektir."
          },
        }
      },
      nl: {
        translation: {
          // here we will place our translations...
          Header: {
            Home: "Start",
            About: "Over ons",
            Services: "Diensten",
            Partners: "Partners",
            Contact: "Contact"
          },

          Home: {
            Header: "Snelle, Betrouwbare en Exquis Ontworpen Softwareoplossingen.",
            Who: "Wie we zijn",
            Leaders: "Leiders in Ontwikkeling:",
            Ded: "Toegewijd aan Uw Succes:",
            Quality: "Kwaliteitsgedreven Softwareontwikkelaars:",
            Services: "Onze Diensten",
            Web: "Web Ontwikkeling",
            App: "App Ontwikkeling",
            SEO: "Social Media Marketing en SEO",
            Contact: "Contact/Volg Ons",
            LText: "Wij zijn erkende leiders op het gebied van software-, web- en app-ontwikkeling. Onze uitgebreide ervaring stelt ons in staat om projecten van alle complexiteiten aan te nemen, zodat uw digitale visie werkelijkheid wordt.",
            DText: "BetStup is meer dan een serviceprovider, we zijn uw toegewijde partners in software-succes. Het succes van uw project is ons uiteindelijke doel, en we zijn toegewijd om dit te laten gebeuren.",
            QText: "Kwaliteit is ons kenmerk. Ons softwareontwikkelingsproces omvat strenge kwaliteitsborging om ervoor te zorgen dat het eindproduct niet alleen functioneel is, maar ook robuust, veilig en betrouwbaar.",
          },

          About: {
            Mission: "Onze Missie:",
            Innovation: "Software Innovatie, Zakelijke Groei.",
            Abus: "Opgericht in 2023, is BetStup toegewijd aan het leveren van de nieuwste technologie en innovatief design om bedrijven te transformeren. Wij zijn erop gericht om ervoor te zorgen dat onze klanten de best mogelijke ervaring hebben, hun operaties efficiënter en klantgerichter maken.",
            Ouar1: "- Wij zijn een vooruitstrevend bedrijf, altijd op de hoogte van technologische vooruitgang.",
            Ouar2: "- Wij begrijpen de unieke behoeften van elk bedrijf en passen onze oplossingen dienovereenkomstig aan.",
            Ouar3: "- Uw succes is ons succes, en wij zijn toegewijd aan het leveren van uitzonderlijke kwaliteit en betrouwbaarheid.",
            Geto: "Neem vandaag nog contact op met BetStup en laat ons uw partner zijn in het benutten van de kracht van technologie om uw bedrijf te verheffen."
          },

          Services: {
            Our: "Onze Diensten",
            Web: `
            Webontwikkeling is onze expertise om uw online aanwezigheid te transformeren. Ons team van bekwame ontwikkelaars maakt websites die niet alleen visueel aantrekkelijk zijn, maar ook naadloos functioneren. Van de gebruikersinterface tot de backend-infrastructuur, we zorgen ervoor dat uw website een krachtig hulpmiddel is voor uw bedrijf. Of u nu een eenvoudige, informatieve website of een complex e-commerce platform nodig heeft, wij staan voor u klaar.
            `,
            App: `
            App-ontwikkeling is waar innovatie de mobiele wereld ontmoet. We zijn gespecialiseerd in het creëren van mobiele applicaties die uw bedrijfsprocessen verbeteren of uw klanten betrekken. Ons team is bedreven in het ontwikkelen voor zowel iOS als Android platforms, en levert gebruiksvriendelijke, functierijke apps. Of het nu gaat om een zakelijke hulpprogramma-app of een communicatieplatform, wij hebben de vaardigheden om uw app-ideeën tot leven te brengen.
            `,
            SEO: `
            In het digitale tijdperk is een geweldig product of dienst niet voldoende; u heeft een sterke online aanwezigheid nodig. Social Media Marketing (SMM) en Zoekmachine Optimalisatie (SEO) zijn essentieel om uw bedrijf op te laten vallen. Onze SMM-experts maken boeiende social media-strategieën om contact te maken met uw publiek, de merkbekendheid te vergroten en conversies te stimuleren. Tegelijkertijd zorgt ons SEO-team ervoor dat uw website hoog scoort in zoekmachine resultaten, waardoor uw zichtbaarheid voor potentiële klanten toeneemt.
            `,
            Sum:`
            Deze diensten zijn ontworpen om uw bedrijf te helpen bloeien in het digitale landschap, van het creëren van een solide webaanwezigheid tot het zorgen dat uw doelgroep u gemakkelijk kan vinden en het benutten van de kracht van social media voor groei.
            `,
          },

          Partners: {
            Headline: "Partners met BetStup",
            Part1: "Wanneer u voor BetStup kiest als uw partner, kiest u voor innovatie, expertise en een toewijding aan uw succes. Wij ontwikkelen niet alleen technologie; wij smeden betekenisvolle partnerschappen om bedrijven naar een hoger niveau te tillen.",
            Part2: "Ons toegewijde team van experts is gepassioneerd over het leveren van baanbrekende oplossingen die uw operaties transformeren en uw klanten betrekken. Met een focus op kwaliteit, transparantie en tijdige levering, maken we uw doelen de onze.",
            Part3: "BetStup is meer dan een serviceprovider; we zijn uw reis naar succes in de digitale wereld. Laten we samenwerken voor vooruitgang, de nieuwste technologieën verkennen en samen pionieren voor de toekomst van uw bedrijf.",
            Working: "BetStup werkt samen met marktleiders:"
          },

          Contact: {
            Headline: "Contacteer ons",
            Dino: "Disclaimer:",
            DinoText: "Hoewel we zorgvuldigheid betrachten bij het beoordelen van onze inhoud, nemen we geen verantwoordelijkheid voor de inhoud van externe links. De inhoud van gelinkte pagina's is de exclusieve verantwoordelijkheid van hun respectievelijke exploitanten.",
            ConUs: "Contacteer ons:",
            ConUsText: "Aarzel niet om op elk moment contact met ons op te nemen. Ons toegewijde team zal uw vragen onmiddellijk behandelen."
          },
        }
      },
      it: {
        translation: {
          // here we will place our translations...
          Header: {
            Home: "Home",
            About: "Chi siamo",
            Services: "Servizi",
            Partners: "Partner",
            Contact: "Contatto",
          },

          Home: {
            Header: "Soluzioni Software Rapide, Affidabili ed Equisite nel Design.",
            Who: "Chi Siamo",
            Leaders: "Leader nello Sviluppo:",
            Ded: "Dedicati al Tuo Successo:",
            Quality: "Sviluppatori Software Orientati alla Qualità:",
            Services: "I Nostri Servizi",
            Web: "Sviluppo Web",
            App: "Sviluppo App",
            SEO: "Marketing sui Social Media e SEO",
            Contact: "Contattaci/Seguici",
            LText: "Siamo riconosciuti come leader nello sviluppo di software, web e app. La nostra vasta esperienza ci permette di affrontare progetti di tutte le complessità, garantendo che la tua visione digitale diventi realtà.",
            DText: "BetStup è più di un fornitore di servizi, siamo i tuoi partner dedicati al successo del software. Il successo del tuo progetto è il nostro obiettivo finale, e siamo impegnati a realizzarlo.",
            QText: "La qualità è il nostro tratto distintivo. Il nostro processo di sviluppo software incorpora rigorose assicurazioni di qualità per garantire che il prodotto finale non sia solo funzionale, ma anche robusto, sicuro e affidabile."
          },

          About: {
            Mission: "La Nostra Missione:",
            Innovation: "Innovare il Software, Elevare l'Impresa.",
            Abus: `
            Fondata nel 2023, BetStup è dedicata
            a fornire l'ultima tecnologia
            e design innovativo per trasformare
            le imprese. Siamo impegnati
            a garantire che i nostri clienti abbiano la
            migliore esperienza possibile, rendendo le loro
            operazioni più efficienti e
            orientate al cliente.`,
            Ouar1: "- Siamo un'azienda proiettata verso il futuro, sempre in prima linea rispetto alle avanzate tecnologiche.",
            Ouar2: "- Comprendiamo le esigenze uniche di ogni impresa e adattiamo di conseguenza le nostre soluzioni.",
            Ouar3: "- Il tuo successo è il nostro successo, e siamo impegnati a fornire qualità e affidabilità eccezionali.",
            Geto: "Contatta BetStup oggi e permettici di essere il tuo partner nell'esplorare il potere della tecnologia per elevare la tua impresa."
          },

          Services: {
            Our: "I Nostri Servizi",
            Web:  `Lo sviluppo web è la nostra competenza nel trasformare la tua presenza online.
            Il nostro team di sviluppatori qualificati crea siti web che non sono
            solo visivamente accattivanti ma funzionano anche senza intoppi.
            Dall'interfaccia utente all'infrastruttura backend,
            ci assicuriamo che il tuo sito web sia uno strumento potente per la tua attività.
            Che tu abbia bisogno di un sito web semplice e informativo o di una piattaforma
            e-commerce complessa, siamo qui per te.`,
            App: `
            Lo sviluppo di app è dove l'innovazione incontra il mondo mobile.
            Siamo specializzati nella creazione di applicazioni mobili che migliorano
            le operazioni aziendali o coinvolgono i tuoi clienti.
            Il nostro team è esperto nello sviluppo sia per piattaforme iOS che 
            Android, fornendo app user-friendly e ricche di funzionalità.
            Che si tratti di un'app di utilità aziendale,
            o di una piattaforma per la comunicazione,
            abbiamo le competenze per dare vita alle tue idee per le app.
            `,
            SEO: `
            Nell'era digitale, avere un ottimo prodotto o servizio non basta; hai bisogno di una forte presenza online.
            Il Social Media Marketing (SMM) e l'Ottimizzazione per i Motori di Ricerca (SEO) sono vitali per far notare la tua attività.
            I nostri esperti di SMM elaborano strategie di social media coinvolgenti per connettersi con il tuo pubblico, aumentare la consapevolezza del marchio,
            e guidare le conversioni. Contemporaneamente, il nostro team SEO garantisce
            che il tuo sito web si classifichi in alto nei risultati dei motori di ricerca, aumentando
            la tua visibilità per i potenziali clienti.
            `,
            Sum: `
            Questi servizi sono progettati per aiutare la tua attività
            a prosperare nel panorama digitale, dalla creazione di una solida presenza web
            all'assicurazione che il tuo pubblico target possa trovarti facilmente
            e sfruttando la potenza dei social media per la crescita.`
          },

          Partners: {
            Headline: "Partner con BetStup",
            Part1: "Scegliendo BetStup come partner, si sceglie l'innovazione, l'esperienza e l'impegno per il vostro successo. Non sviluppiamo solo tecnologia; creiamo partenariati significativi per portare le imprese al livello successivo.",
            Part2: "Il nostro team dedicato di esperti è appassionato di fornire soluzioni all'avanguardia che trasformano le vostre operazioni e coinvolgono i vostri clienti. Con un focus su qualità, trasparenza e consegna puntuale, rendiamo i vostri obiettivi i nostri.",
            Part3: "BetStup è più di un fornitore di servizi; siamo il vostro percorso verso il successo nel mondo digitale. Facciamo squadra per il progresso, esploriamo le ultime tecnologie e guidiamo insieme il futuro della vostra impresa.",
            Working: "BetStup sta lavorando con leader del settore:"
          },

          Contact: {
            Headline: "Contattaci",
            Dino: "Avviso di esonero di responsabilità:",
            DinoText: "Sebbene esercitiamo diligenza nella revisione del nostro contenuto, non assumiamo responsabilità per il contenuto dei collegamenti esterni. Il contenuto delle pagine collegate è responsabilità esclusiva dei rispettivi operatori.",
            ConUs: "Contattaci:",
            ConUsText: "Non esitare a contattarci in qualsiasi momento. Il nostro team dedicato risponderà prontamente alle tue richieste."
          },
        }
      }
    }
  });

export default i18n;